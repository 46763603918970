import "ant-design-vue/dist/antd.less";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import locale from './locale';
import "./app.less";
import "./router/router-guards.js";

import {
  Button,
  message,
  Layout,
  Menu,
  Breadcrumb,
  Carousel,
  Empty,
  Spin,
  Switch,
  Tag,
  Pagination,
  ConfigProvider,
  Affix,
  Tooltip,
  Row,
  Col,
  Form,
  Modal,
  Descriptions,
  Image,
  Drawer,
  Divider,
  Statistic,
  Space,
  Input,
  InputNumber,
  Alert,
  Popover,
  Dropdown,
  Steps,
  Progress,
  List,
  Badge,
  Select,
  Upload,
  DatePicker,
  TimePicker,
  Checkbox,
  Tabs,
  Result,
  // CheckboxGroup,
} from "ant-design-vue";
// import "ant-design-vue/dist/antd.variable.less";

const app = createApp(App);
app
  .use(store)
  .use(router)
  .use(locale)
  .use(Button)
  .use(Layout)
  .use(Menu)
  .use(Breadcrumb)
  .use(Carousel)
  .use(Empty)
  .use(Spin)
  .use(Switch)
  .use(Tag)
  .use(Pagination)
  .use(ConfigProvider)
  .use(Affix)
  .use(Tooltip)
  .use(Row)
  .use(Col)
  .use(Form)
  .use(Modal)
  .use(Descriptions)
  .use(Image)
  .use(Drawer)
  .use(Divider)
  .use(Statistic)
  .use(Space)
  .use(Input)
  .use(InputNumber)
  .use(Alert)
  .use(Popover)
  .use(Dropdown)
  .use(Steps)
  .use(Progress)
  .use(List)
  .use(Badge)
  .use(Select)
  .use(Upload)
  .use(DatePicker)
  .use(TimePicker)
  .use(Checkbox)
  .use(Tabs)
  .use(Result)
  // .use(CheckboxGroup)
  .mount("#app");
// useIcons(app);
app.config.globalProperties.$message = message;
