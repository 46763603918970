import { createRouter, createWebHashHistory } from "vue-router";
// import Home from "../views/Home.vue";
import Layouts from "@/components/layouts/index.vue";

export const staticRoutes = [
  {
    path: "/",
    name: "Home",
    component: Layouts,
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "Home",
        meta: {
          title: "index"
        },
        component: () => import("../views/home/Home.vue")
      },
      {
        path: "/login",
        name: "Login",
        meta: {
          title: "login"
        },
        component: () => import("../views/login.vue")
      },
      {
        path: "/register",
        name: "Register",
        meta: {
          title: "register"
        },
        component: () => import("../views/register.vue")
      },
      {
        path: "/privacy",
        name: "Privacy",
        meta: {
          title: "服务政策与隐私协议"
        },
        component: () => import("../views/privacy.vue")
      },
      {
        path: "/ucenter",
        name: "Ucenter",
        meta: {
          title: "个人中心"
        },
        component: () => import("../views/ucenter/index.vue")
      },
      {
        path: "/notice",
        name: "Notice",
        meta: {
          title: "通知公告"
        },
        component: () => import("../views/notice/index.vue")
      },
      {
        path: "/notice/detail",
        name: "NoticeDetail",
        meta: {
          title: "通知公告"
        },
        component: () => import("../views/notice/detail.vue")
      },
      {
        path: "/park",
        name: "Park",
        meta: {
          title: "园区信息"
        },
        component: () => import("../views/park/index.vue")
      },
      {
        path: "/park/site",
        name: "ParkSite",
        meta: {
          title: "园区信息"
        },
        component: () => import("../views/park/site.vue")
      },
      {
        path: "/activity",
        name: "Activity",
        meta: {
          title: "园区活动"
        },
        component: () => import("../views/activity/index.vue")
      },
      {
        path: "/activity/detail",
        name: "ActivityDetail",
        meta: {
          title: "园区活动"
        },
        component: () => import("../views/activity/detail.vue")
      },
      {
        path: "/activity/apply",
        name: "ActivityApply",
        meta: {
          title: "园区活动"
        },
        component: () => import("../views/activity/apply.vue")
      },
      {
        path: "/activity/applyDetail",
        name: "ActivityApplyDetail",
        meta: {
          title: "园区活动"
        },
        component: () => import("../views/activity/applyDetail.vue")
      },
      {
        path: "/provider",
        name: "Provider",
        meta: {
          title: "咨询服务"
        },
        component: () => import("../views/provider/index.vue")
      },
      {
        path: "/provider/detail",
        name: "ProviderDetail",
        meta: {
          title: "咨询服务"
        },
        component: () => import("../views/provider/detail.vue")
      },
      {
        path: "/post",
        name: "Post",
        meta: {
          title: "互动"
        },
        component: () => import("../views/post/index.vue")
      },
      {
        path: "/post/detail",
        name: "PostDetail",
        meta: {
          title: "互动"
        },
        component: () => import("../views/post/detail.vue")
      },
      {
        path: "/map",
        name: "Map",
        meta: {
          title: "导航"
        },
        component: () => import("../views/map.vue")
      },
      {
        path: "/enterprise",
        name: "Enterprise",
        meta: {
          title: "企业入驻"
        },
        component: () => import("../views/enterprise/index.vue")
      },
      {
        path: "/enterprise/detail",
        name: "EnterpriseDetail",
        meta: {
          title: "企业入驻"
        },
        component: () => import("../views/enterprise/detail.vue")
      },
      {
        path: "/enterprise/flow",
        name: "EnterpriseFlow",
        meta: {
          title: "企业入驻"
        },
        component: () => import("../views/enterprise/flow.vue")
      },
      {
        path: "/enterprise/apply",
        name: "EnterpriseApply",
        meta: {
          title: "企业入驻"
        },
        component: () => import("../views/enterprise/apply.vue")
      },
      {
        path: "/enterprise/formList",
        name: "EnterpriseFormList",
        meta: {
          title: "数据填报"
        },
        component: () => import("../views/enterprise/formList.vue")
      },
      {
        path: "/enterprise/formDetail",
        name: "EnterpriseFormDetail",
        meta: {
          title: "数据填报"
        },
        component: () => import("../views/enterprise/formDetail.vue")
      },
      {
        path: "/declaration",
        name: "Declaration",
        meta: {
          title: "申报服务"
        },
        component: () => import("../views/declaration/index.vue")
      },
      {
        path: "/declaration/detail",
        name: "DeclarationDetail",
        meta: {
          title: "申报服务"
        },
        component: () => import("../views/declaration/detail.vue")
      },
      {
        path: "/declaration/decform",
        name: "DeclarationDecform",
        meta: {
          title: "申报服务"
        },
        component: () => import("../views/declaration/decform.vue")
      },
      {
        path: "/declaration/recordlist",
        name: "Declarationrecordlist",
        meta: {
          title: "申报记录"
        },
        component: () => import("../views/declaration/recordlist.vue")
      },
      {
        path: "/repair",
        name: "Repair",
        meta: {
          title: "报事报修"
        },
        component: () => import("../views/repair/index.vue")
      },
      {
        path: "/repair/detail",
        name: "RepairDetail",
        meta: {
          title: "报事报修"
        },
        component: () => import("../views/repair/detail.vue")
      },
      {
        path: "/repair/apply",
        name: "RepairApply",
        meta: {
          title: "报事报修"
        },
        component: () => import("../views/repair/apply.vue")
      },
      {
        path: "/job",
        name: "Job",
        meta: {
          title: "招聘服务"
        },
        component: () => import("../views/job/index.vue")
      },
      {
        path: "/job/detail",
        name: "JobDetail",
        meta: {
          title: "招聘服务"
        },
        component: () => import("../views/job/detail.vue")
      },
      {
        path: "/job/form",
        name: "JobForm",
        meta: {
          title: "招聘服务"
        },
        component: () => import("../views/job/form.vue")
      },
      {
        path: "/job/myJob",
        name: "MyJob",
        meta: {
          title: "招聘服务"
        },
        component: () => import("../views/job/myJob.vue")
      },
      {
        path: "/meeting",
        name: "Meeting",
        meta: {
          title: "会议室预定"
        },
        component: () => import("../views/meeting/index.vue")
      },
      {
        path: "/meeting/record",
        name: "MeetingRecord",
        meta: {
          title: "会议室预定"
        },
        component: () => import("../views/meeting/record.vue")
      },
      {
        path: "/meeting/detail",
        name: "MeetingDetail",
        meta: {
          title: "会议室预定"
        },
        component: () => import("../views/meeting/detail.vue")
      },
      {
        path: "/meeting/apply",
        name: "MeetingApply",
        meta: {
          title: "会议室预定"
        },
        component: () => import("../views/meeting/apply.vue")
      },
      {
        path: "/meeting/applyDetail",
        name: "MeetingApplyDetail",
        meta: {
          title: "会议室预定"
        },
        component: () => import("../views/meeting/applyDetail.vue")
      },
      {
        path: "/payment",
        name: "Payment",
        meta: {
          title: "费用缴纳"
        },
        component: () => import("../views/payment/index.vue")
      },
      {
        path: "/payment/record",
        name: "PaymentRecord",
        meta: {
          title: "费用缴纳"
        },
        component: () => import("../views/payment/record.vue")
      },
      {
        path: "/exam/index",
        name: "exam",
        meta: {
          title: "测评考试"
        },
        component: () => import("../views/exam/index.vue")
      },
      {
        path: "/exam/examdata",
        name: "examdata",
        meta: {
          title: "测评考试"
        },
        component: () => import("../views/exam/examdata.vue")
      },
      {
        path: "/exam/examquestion",
        name: "examquestion",
        meta: {
          title: "测评考试"
        },
        component: () => import("../views/exam/examquestion.vue")
      },
      {
        path: "/exam/scoredata",
        name: "scoredata",
        meta: {
          title: "测评考试",
          bg: "#f3f5f8"
        },
        component: () => import("../views/exam/scoredata.vue")
      },
      {
        path: "/course/index",
        name: "course",
        meta: {
          title: "课程培训"
        },
        component: () => import("../views/course/index.vue")
      },
      {
        path: "/course/detail",
        name: "coursedetail",
        meta: {
          title: "课程培训"
        },
        component: () => import("../views/course/detail.vue")
      },
      {
        path: "/course/courseresource",
        name: "courseresource",
        meta: {
          title: "课程培训"
        },
        component: () => import("../views/course/courseresource.vue")
      },
      {
        path: "/course/mycourse",
        name: "mycourse",
        meta: {
          title: "我的课程"
        },
        component: () => import("../views/course/mycourse.vue")
      },
      {
        path: "/studio",
        name: "Studio",
        meta: {
          title: "直播室预定"
        },
        component: () => import("../views/studio/index.vue")
      },
      {
        path: "/studio/record",
        name: "StudioRecord",
        meta: {
          title: "直播室预定"
        },
        component: () => import("../views/studio/record.vue")
      },
      {
        path: "/studio/detail",
        name: "StudioDetail",
        meta: {
          title: "直播室预定"
        },
        component: () => import("../views/studio/detail.vue")
      },
      {
        path: "/studio/apply",
        name: "StudioApply",
        meta: {
          title: "直播室预定"
        },
        component: () => import("../views/studio/apply.vue")
      },
      {
        path: "/studio/applyDetail",
        name: "StudioApplyDetail",
        meta: {
          title: "直播室预定"
        },
        component: () => import("../views/studio/applyDetail.vue")
      },
      {
        path: "/intercept",
        name: "Intercept",
        component: () => import("../views/Intercept.vue")
      }
    ]
  }
];
export const routes = [
  {
    path: "/index",
    name: "Home",
    meta: {
      title: "index"
    },
    component: () => import("../views/home/Home.vue")
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "login"
    },
    component: () => import("../views/login.vue")
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "register"
    },
    component: () => import("../views/register.vue")
  },
  {
    path: "/privacy",
    name: "Privacy",
    meta: {
      title: "服务政策与隐私协议"
    },
    component: () => import("../views/privacy.vue")
  },
  {
    path: "/ucenter",
    name: "Ucenter",
    meta: {
      title: "个人中心"
    },
    component: () => import("../views/ucenter/index.vue")
  },
  {
    path: "/notice",
    name: "Notice",
    meta: {
      title: "通知公告"
    },
    component: () => import("../views/notice/index.vue")
  },
  {
    path: "/notice/detail",
    name: "NoticeDetail",
    meta: {
      title: "通知公告"
    },
    component: () => import("../views/notice/detail.vue")
  },
  {
    path: "/park",
    name: "Park",
    meta: {
      title: "园区信息"
    },
    component: () => import("../views/park/index.vue")
  },
  {
    path: "/park/site",
    name: "ParkSite",
    meta: {
      title: "园区信息"
    },
    component: () => import("../views/park/site.vue")
  },
  {
    path: "/activity",
    name: "Activity",
    meta: {
      title: "园区活动"
    },
    component: () => import("../views/activity/index.vue")
  },
  {
    path: "/activity/detail",
    name: "ActivityDetail",
    meta: {
      title: "园区活动"
    },
    component: () => import("../views/activity/detail.vue")
  },
  {
    path: "/activity/apply",
    name: "ActivityApply",
    meta: {
      title: "园区活动"
    },
    component: () => import("../views/activity/apply.vue")
  },
  {
    path: "/activity/applyDetail",
    name: "ActivityApplyDetail",
    meta: {
      title: "园区活动"
    },
    component: () => import("../views/activity/applyDetail.vue")
  },
  {
    path: "/provider",
    name: "Provider",
    meta: {
      title: "咨询服务"
    },
    component: () => import("../views/provider/index.vue")
  },
  {
    path: "/provider/detail",
    name: "ProviderDetail",
    meta: {
      title: "咨询服务"
    },
    component: () => import("../views/provider/detail.vue")
  },
  {
    path: "/post",
    name: "Post",
    meta: {
      title: "互动"
    },
    component: () => import("../views/post/index.vue")
  },
  {
    path: "/post/detail",
    name: "PostDetail",
    meta: {
      title: "互动"
    },
    component: () => import("../views/post/detail.vue")
  },
  {
    path: "/map",
    name: "Map",
    meta: {
      title: "导航"
    },
    component: () => import("../views/map.vue")
  },
  {
    path: "/enterprise",
    name: "Enterprise",
    meta: {
      title: "企业入驻"
    },
    component: () => import("../views/enterprise/index.vue")
  },
  {
    path: "/enterprise/detail",
    name: "EnterpriseDetail",
    meta: {
      title: "企业入驻"
    },
    component: () => import("../views/enterprise/detail.vue")
  },
  {
    path: "/enterprise/flow",
    name: "EnterpriseFlow",
    meta: {
      title: "企业入驻"
    },
    component: () => import("../views/enterprise/flow.vue")
  },
  {
    path: "/enterprise/apply",
    name: "EnterpriseApply",
    meta: {
      title: "企业入驻"
    },
    component: () => import("../views/enterprise/apply.vue")
  },
  {
    path: "/enterprise/formList",
    name: "EnterpriseFormList",
    meta: {
      title: "数据填报"
    },
    component: () => import("../views/enterprise/formList.vue")
  },
  {
    path: "/enterprise/formDetail",
    name: "EnterpriseFormDetail",
    meta: {
      title: "数据填报"
    },
    component: () => import("../views/enterprise/formDetail.vue")
  },
  {
    path: "/declaration",
    name: "Declaration",
    meta: {
      title: "申报服务"
    },
    component: () => import("../views/declaration/index.vue")
  },
  {
    path: "/declaration/detail",
    name: "DeclarationDetail",
    meta: {
      title: "申报服务"
    },
    component: () => import("../views/declaration/detail.vue")
  },
  {
    path: "/declaration/decform",
    name: "DeclarationDecform",
    meta: {
      title: "申报服务"
    },
    component: () => import("../views/declaration/decform.vue")
  },
  {
    path: "/declaration/recordlist",
    name: "Declarationrecordlist",
    meta: {
      title: "申报记录"
    },
    component: () => import("../views/declaration/recordlist.vue")
  },
  {
    path: "/repair",
    name: "Repair",
    meta: {
      title: "报事报修"
    },
    component: () => import("../views/repair/index.vue")
  },
  {
    path: "/repair/detail",
    name: "RepairDetail",
    meta: {
      title: "报事报修"
    },
    component: () => import("../views/repair/detail.vue")
  },
  {
    path: "/repair/apply",
    name: "RepairApply",
    meta: {
      title: "报事报修"
    },
    component: () => import("../views/repair/apply.vue")
  },
  {
    path: "/job",
    name: "Job",
    meta: {
      title: "招聘服务"
    },
    component: () => import("../views/job/index.vue")
  },
  {
    path: "/job/detail",
    name: "JobDetail",
    meta: {
      title: "招聘服务"
    },
    component: () => import("../views/job/detail.vue")
  },
  {
    path: "/job/form",
    name: "JobForm",
    meta: {
      title: "招聘服务"
    },
    component: () => import("../views/job/form.vue")
  },
  {
    path: "/job/myJob",
    name: "MyJob",
    meta: {
      title: "招聘服务"
    },
    component: () => import("../views/job/myJob.vue")
  },
  {
    path: "/meeting",
    name: "Meeting",
    meta: {
      title: "会议室预定"
    },
    component: () => import("../views/meeting/index.vue")
  },
  {
    path: "/meeting/record",
    name: "MeetingRecord",
    meta: {
      title: "会议室预定"
    },
    component: () => import("../views/meeting/record.vue")
  },
  {
    path: "/meeting/detail",
    name: "MeetingDetail",
    meta: {
      title: "会议室预定"
    },
    component: () => import("../views/meeting/detail.vue")
  },
  {
    path: "/meeting/apply",
    name: "MeetingApply",
    meta: {
      title: "会议室预定"
    },
    component: () => import("../views/meeting/apply.vue")
  },
  {
    path: "/meeting/applyDetail",
    name: "MeetingApplyDetail",
    meta: {
      title: "会议室预定"
    },
    component: () => import("../views/meeting/applyDetail.vue")
  },
  {
    path: "/payment",
    name: "Payment",
    meta: {
      title: "费用缴纳"
    },
    component: () => import("../views/payment/index.vue")
  },
  {
    path: "/payment/record",
    name: "PaymentRecord",
    meta: {
      title: "费用缴纳"
    },
    component: () => import("../views/payment/record.vue")
  },
  {
    path: "/exam/index",
    name: "exam",
    meta: {
      title: "测评考试"
    },
    component: () => import("../views/exam/index.vue")
  },
  {
    path: "/exam/examdata",
    name: "examdata",
    meta: {
      title: "测评考试"
    },
    component: () => import("../views/exam/examdata.vue")
  },
  {
    path: "/exam/examquestion",
    name: "examquestion",
    meta: {
      title: "测评考试"
    },
    component: () => import("../views/exam/examquestion.vue")
  },
  {
    path: "/exam/scoredata",
    name: "scoredata",
    meta: {
      title: "测评考试",
      bg: "#f3f5f8"
    },
    component: () => import("../views/exam/scoredata.vue")
  },
  {
    path: "/course/index",
    name: "course",
    meta: {
      title: "课程培训"
    },
    component: () => import("../views/course/index.vue")
  },
  {
    path: "/course/detail",
    name: "coursedetail",
    meta: {
      title: "课程培训"
    },
    component: () => import("../views/course/detail.vue")
  },
  {
    path: "/course/courseresource",
    name: "courseresource",
    meta: {
      title: "课程培训"
    },
    component: () => import("../views/course/courseresource.vue")
  },
  {
    path: "/course/mycourse",
    name: "mycourse",
    meta: {
      title: "我的课程"
    },
    component: () => import("../views/course/mycourse.vue")
  },
  {
    path: "/studio",
    name: "Studio",
    meta: {
      title: "直播室预定"
    },
    component: () => import("../views/studio/index.vue")
  },
  {
    path: "/studio/record",
    name: "StudioRecord",
    meta: {
      title: "直播室预定"
    },
    component: () => import("../views/studio/record.vue")
  },
  {
    path: "/studio/detail",
    name: "StudioDetail",
    meta: {
      title: "直播室预定"
    },
    component: () => import("../views/studio/detail.vue")
  },
  {
    path: "/studio/apply",
    name: "StudioApply",
    meta: {
      title: "直播室预定"
    },
    component: () => import("../views/studio/apply.vue")
  },
  {
    path: "/studio/applyDetail",
    name: "StudioApplyDetail",
    meta: {
      title: "直播室预定"
    },
    component: () => import("../views/studio/applyDetail.vue")
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFund",
    component: () => import("@/views/error/index.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: staticRoutes,
  scrollBehavior: () => {
    return {
      el: ".ant-layout-content",
      top: 64
    };
  }
});

export default router;
