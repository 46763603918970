import request from "@/utils/request";
// 发送短信验证码
export async function sendCodeSms(params) {
  return request.post("/user/login/sendCodeSms", params);
}
// 登录
export async function onLogin(params) {
  return request.post("/user/login/onLoginByPhoneForPc", params);
}
// 通过账号密码登录
export async function onLoginByPassword(params) {
  return request.post("/user/login/onLoginByPassword", params);
}
// 修改用户密码
export async function updateUserPasswordData(params) {
  return request.post("/user/user/updateUserPasswordData", params);
}
//获取用户详情
export async function getUserData(params) {
  return request.post("/user/user/getUserData", params);
}
// 切换用户最近所在园区
export async function updateUserParkIdData(params) {
  return request.post("/user/user/updateUserParkIdData", params);
}
//切换用户最近使用企业
export async function updateUserEnterpriseIdData(params) {
  return request.post("/user/user/updateUserEnterpriseIdData", params);
}
//获取轮播图列表
export async function getSliderList(params) {
  return request.post("/user/basic/getSliderList", params);
}
//获取通知公告列表
export async function getNoticeList(params) {
  return request.post("/user/basic/getNoticeList", params);
}
//获取通知公告详情
export async function getNoticeData(params) {
  return request.post("/user/basic/getNoticeData", params);
}
//获取园区列表
export async function getParkList(params) {
  return request.post("/user/basic/getParkList", params);
}
// 获取园区详情
export async function getParkData(params) {
  return request.post("/user/basic/getParkData", params);
}
// 获取园区展示列表
export async function getParkShowList(params) {
  return request.post("/user/basic/getParkShowList", params);
}
// 获取场地列表
export async function getSiteList(params) {
  return request.post("/user/basic/getSiteList", params);
}
//获取场地详情
export async function getSiteData(params) {
  return request.post("/user/basic/getSiteData", params);
}
//获取入驻企业列表
export async function getEnterpriseList(params) {
  return request.post("/user/basic/getEnterpriseList", params);
}
// 获取活动列表
export async function getActivityList(params) {
  return request.post("/user/basic/getActivityList", params);
}
//获取活动详情
export async function getActivityData(params) {
  return request.post("/user/basic/getActivityData", params);
}
// 活动报名
export async function insertActivityApplyData(params) {
  return request.post("/user/activity/insertActivityApplyData", params);
}
//修改活动报名状态（仅取消）
export async function updateActivityApplyStatusData(params) {
  return request.post("/user/activity/updateActivityApplyStatusData", params);
}
//获取活动报名列表
export async function getActivityApplyList(params) {
  return request.post("/user/activity/getActivityApplyList", params);
}
// 获取活动报名详情
export async function getActivityApplyData(params) {
  return request.post("/user/activity/getActivityApplyData", params);
}

//获取帖子分类列表
export async function getPostTypeList(params) {
  return request.post("/user/basic/getPostTypeList", params);
}
// 获取帖子列表
export async function getPostList(params) {
  return request.post("/user/basic/getPostList", params);
}
// 获取帖子详情
export async function getPostData(params) {
  return request.post("/user/basic/getPostData", params);
}
// 新增/修改我的帖子/评论
export async function setPostData(params) {
  return request.post("/user/post/setPostData", params);
}
// 修改帖子/评论状态
export async function updatePostStatusData(params) {
  return request.post("/user/post/updatePostStatusData", params);
}
//获取我的帖子/评论列表
export async function getUserPostList(params) {
  return request.post("/user/post/getUserPostList", params);
}
//点赞（主贴）
export async function insertPostFavourData(params) {
  return request.post("/user/post/insertPostFavourData", params);
}
// 取消点赞（主贴）
export async function deletePostFavourData(params) {
  return request.post("/user/post/deletePostFavourData", params);
}
// 获取报修类型列表
export async function getRepairTypeList(params) {
  return request.post("/user/basic/getRepairTypeList", params);
}
// 获取我的报修列表
export async function getRepairList(params) {
  return request.post("/user/repair/getRepairList", params);
}
// 获取我的报修详情
export async function getRepairData(params) {
  return request.post("/user/repair/getRepairData", params);
}
// 获取报修场地备选项列表
export async function getRepairSiteList(params) {
  return request.post("/user/repair/getRepairSiteList", params);
}
// 新增报修
export async function insertRepairData(params) {
  return request.post("/user/repair/insertRepairData", params);
}

// 取消报修
export async function updateRepairCancelStatusData(params) {
  return request.post("/user/repair/updateRepairCancelStatusData", params);
}

// 获取企业列表
export async function getEnterpriseList2(params) {
  return request.post("/user/enterprise/getEnterpriseList", params);
}
// 获取企业详情
export async function getEnterpriseData(params) {
  return request.post("/user/enterprise/getEnterpriseData", params);
}
// 获取企业用户列表
export async function getEnterpriseUserList(params) {
  return request.post("/user/enterprise/getEnterpriseUserList", params);
}
//修改企业用户是否管理员
export async function updateEnterpriseUserIsmanagerData(params) {
  return request.post("/user/enterprise/updateEnterpriseUserIsmanagerData", params);
}
// 新增企业用户
export async function insertEnterpriseUserData(params) {
  return request.post("/user/enterprise/insertEnterpriseUserData", params);
}
// 删除企业用户
export async function deleteEnterpriseUserData(params) {
  return request.post("/user/enterprise/deleteEnterpriseUserData", params);
}
//入驻填写信息提交
export async function insertEnterpriseData(params) {
  return request.post("/user/enterprise/insertEnterpriseData", params);
}
// 入驻填写信息重新提交
export async function updateEnterpriseFirstData(params) {
  return request.post("/user/enterprise/updateEnterpriseFirstData", params);
}
// 入驻上传资料提交
export async function updateEnterpriseSecondData(params) {
  return request.post("/user/enterprise/updateEnterpriseSecondData", params);
}
//获取企业填报列表
export async function getEnterpriseFormList(params) {
  return request.post("/user/enterprise/getEnterpriseFormList", params);
}
// 获取企业填报详情
export async function getEnterpriseFormData(params) {
  return request.post("/user/enterprise/getEnterpriseFormData", params);
}
// 填报企业数据
export async function updateEnterpriseFormData(params) {
  return request.post("/user/enterprise/updateEnterpriseFormData", params);
}
//获取缴费列表
export async function getPaymentList(params) {
  return request.post("/user/payment/getPaymentList", params);
}
//获取我的缴费订单列表
export async function getPaymentOrderList(params) {
  return request.post("/user/payment/getPaymentOrderList", params);
}
//获取我的缴费订单详情
export async function getPaymentOrderData(params) {
  return request.post("/user/payment/getPaymentOrderData", params);
}
// 取消我的未缴费订单
export async function updatePaymentOrderCancelData(params) {
  return request.post("/user/payment/updatePaymentOrderCancelData", params);
}
// 创建缴费订单
export async function insertPaymentOrderData(params) {
  return request.post("/user/payment/insertPaymentOrderData", params);
}
// 支付缴费订单
export async function updatePaymentOrderPayData(params) {
  return request.post("/user/payment/updatePaymentOrderPayData", params);
}
// 获取职位列表
export async function getJobList(params) {
  return request.post("/user/basic/getJobList", params);
}
// 获取职位详情
export async function getJobData(params) {
  return request.post("/user/basic/getJobData", params);
}
// 获取我的职位列表
export async function getUserJobList(params) {
  return request.post("/user/job/getUserJobList", params);
}
// 新增/修改我的职位
export async function setJobData(params) {
  return request.post("/user/job/setJobData", params);
}
// 修改我的职位状态
export async function updateJobStatusData(params) {
  return request.post("/user/job/updateJobStatusData", params);
}
// 获取会议室列表
export async function getMeetingList(params) {
  return request.post("/user/basic/getMeetingList", params);
}
// 获取会议室详情
export async function getMeetingData(params) {
  return request.post("/user/basic/getMeetingData", params);
}
//获取我的会议室预定列表
export async function getUserMeetingBookList(params) {
  return request.post("/user/meeting/getUserMeetingBookList", params);
}
//获取我的会议室预定详情
export async function getUserMeetingBookData(params) {
  return request.post("/user/meeting/getUserMeetingBookData", params);
}
// 获取指定日期已预约时间列表
export async function getMeetingBookTimeListByDate(params) {
  return request.post("/user/meeting/getMeetingBookTimeListByDate", params);
}
//新增会议室预约
export async function insertMeetingBookData(params) {
  return request.post("/user/meeting/insertMeetingBookData", params);
}
// 修改会议室预约状态
export async function updateMeetingBookStatusData(params) {
  return request.post("/user/meeting/updateMeetingBookStatusData", params);
}
// 获取咨询服务机构列表
export async function getProviderList(params) {
  return request.post("/user/basic/getProviderList", params);
}
//获取咨询服务机构详情
export async function getProviderData(params) {
  return request.post("/user/basic/getProviderData", params);
}
// 获取课程列表
export async function getCourseList(params) {
  return request.post("/user/basic/getCourseList", params);
}
//获取我的课程列表
export async function getUserCourseList(params) {
  return request.post("/user/course/getUserCourseList", params);
}
//获取课程详情
export async function getCourseData(params) {
  return request.post("/user/basic/getCourseData", params);
}
//获取课程资源详情
export async function getCourseResourceData(params) {
  return request.post("/user/course/getCourseResourceData", params);
}
//获取课程课件详情
export async function getCourseWareData(params) {
  return request.post("/user/course/getCourseWareData", params);
}
//课程报名
export async function insertCourseSignupData(params) {
  return request.post("/user/course/insertCourseSignupData", params);
}
// 学习上报（每隔10s一次）
export async function setCourseRecordData(params) {
  return request.post("/user/course/setCourseRecordData", params);
}
// 获取我的考试列表
export async function getUserExamList(params) {
  return request.post("/user/exam/getUserExamList", params);
}
// 获取我的考试详情
export async function getUserExamData(params) {
  return request.post("/user/exam/getUserExamData", params);
}
// 开始考试
export async function insertExamRecordData(params) {
  return request.post("/user/exam/insertExamRecordData", params);
}
// 获取考试题目
export async function getExamQuestionList(params) {
  return request.post("/user/exam/getExamQuestionList", params);
}
//提交考试结果
export async function updateExamRecordScoreData(params) {
  return request.post("/user/exam/updateExamRecordScoreData", params);
}
//获取考试记录详情
export async function getExamRecordData(params) {
  return request.post("/user/exam/getExamRecordData", params);
}
//获取申报服务政策列表
export async function getDeclarationList(params) {
  return request.post("/user/basic/getDeclarationList", params);
}
//获取申报服务政策详情
export async function getDeclarationData(params) {
  return request.post("/user/basic/getDeclarationData", params);
}
// 获取直播室列表
export async function getStudioList(params) {
  return request.post("/user/basic/getStudioList", params);
}
// 获取直播室详情
export async function getStudioData(params) {
  return request.post("/user/basic/getStudioData", params);
}
//获取我的直播室预定列表
export async function getUserStudioBookList(params) {
  return request.post("/user/Studio/getUserStudioBookList", params);
}
//获取我的直播室预定详情
export async function getUserStudioBookData(params) {
  return request.post("/user/Studio/getUserStudioBookData", params);
}
// 获取指定日期已预约时间列表
export async function getStudioBookTimeListByDate(params) {
  return request.post("/user/Studio/getStudioBookTimeListByDate", params);
}
//新增直播室预约
export async function insertStudioBookData(params) {
  return request.post("/user/Studio/insertStudioBookData", params);
}
// 修改直播室预约状态
export async function updateStudioBookStatusData(params) {
  return request.post("/user/Studio/updateStudioBookStatusData", params);
}

export async function updateEnterpriseLeaveStatusData(params) {
  return request.post("/user/enterprise/updateEnterpriseLeaveStatusData", params);
}
// 获取资产
export async function getPropertyList(params) {
  return request.post("/user/basic/getPropertyList", params);
}


export async function getDeclarationRecordList(params) {
  return request.post("/user/declaration/getDeclarationRecordList", params);
}
export async function setDeclarationRecordData(params) {
  return request.post("/user/declaration/setDeclarationRecordData", params);
}
export async function updateDeclarationRecordStatusData(params) {
  return request.post("/user/declaration/updateDeclarationRecordStatusData", params);
}
export async function getDeclarationRecordData(params) {
  return request.post("/user/declaration/getDeclarationRecordData", params);
}
export async function importSheet(params) {
  return request.post('/user/sheet/importSheet', params);
}
export async function importEnterpriseUserSheet(params) {
  return request.post('/user/sheet/importEnterpriseUserSheet', params);
}
