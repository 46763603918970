import router from "../router";
// import { useRouter } from "vue-router";
import { Modal } from "ant-design-vue";
import store from "../store";
import ls from "@/utils/local-storage";

const whiteList = ["Home", "/login", "/register", "/privacy", "/", "/index"];
const homePath = "/login";

const allowList = [
  "/job",
  "/login",
  "/register",
  "/ucenter",
  "/",
  "/park",
  "/map",
  "/park/site",
  "/privacy",
  "/index",
  "/enterprise",
  "/enterprise/apply",
  "/enterprise/detail",
  "/enterprise/flow",
  //后加的
  // "/course/index",
  // "/exam/index"
];
const enterpriseOnlineAllowList = [
  "/job",
  "/login",
  "/register",
  "/ucenter",
  "/",
  "/park",
  "/map",
  "/park/site",
  "/privacy",
  "/index",
  "/enterprise",
  "/enterprise/apply",
  "/enterprise/detail",
  "/enterprise/flow",
  "/studio",
  "/studio/detail",
  "/studio/record",
  "/studio/applyDetail",
  "/meeting",
  "/meeting/detail",
  "/meeting/record",
  "/meeting/applyDetail",
  "/exam/index",
  "/exam/examdata",
  "/exam/examquestion",
  "/exam/scoredata",
  "/course/index",
  "/course/detail",
  "/course/courseresource",
  "/course/mycourse",
  "/notice",
  "/notice/detail",
  "/activity",
  "/activity/detail",
  "/provider",
  "/provider/detail",
  // "/declaration",
  // "/declaration/detail",
];
const userAllowList = [
  "/studio",
  "/studio/detail",
  "/studio/record",
  "/studio/applyDetail",
  "/meeting",
  "/meeting/detail",
  "/meeting/record",
  "/meeting/applyDetail",
  "/enterprise/formList",
  "/enterprise/formDetail",
  "/repair",
  "/repair/apply",
  "/declaration",
  "/declaration/detail",
];
const navigateTo = (_path, _query) => {
  const userToken = ls.get("rzUserId"); // token check
  if (!userToken) {
    // 白名单路由列表检查
    if (whiteList.includes(_path)) {
      console.log("path", _path);
      router.push({ path: _path, query: _query });
    } else {
      router.replace({ path: homePath, query: _query });
    }
    return;
  }
  if (store.state.user.user_enterprise_id) {
    if (store.state.user?.enterprise_user?.enterprise_user_ismanager) {
      if (store.state.user.enterprise_user.enterprise_source_id == 1) {
        router.push({ path: _path, query: _query });
      } else if (
        store.state.user.enterprise_user.enterprise_source_id  == 2 &&
        enterpriseOnlineAllowList.includes(_path)
      ) {
        router.push({ path: _path, query: _query });
      } else {
        Modal.error({
          title: "您没有权限操作",
          okText: "确定",
          centered: true,
          onOk() {},
        });
      }
    } else {
      if (!userAllowList.includes(_path)) {
        router.push({ path: _path, query: _query });
      } else {
        Modal.error({
          title: "您没有权限操作",
          okText: "确定",
          centered: true,
          onOk() {},
        });
      }
    }
  } else {
    if (allowList.includes(_path)) {
      router.push({ path: _path, query: _query });
    } else {
      Modal.error({
        title: "您没有权限操作",
        okText: "确定",
        centered: true,
        onOk() {},
      });
    }
  }
};
const replaceTo = (_path, _query) => {
  if (store.state.user.user_enterprise_id) {
    // router.replace({ path: _path, query: _query });
    if (store.state.user?.enterprise_user?.enterprise_user_ismanager) {
      router.replace({ path: _path, query: _query });
    } else {
      if (!userAllowList.includes(_path)) {
        router.replace({ phat: _path, query: _query });
      } else {
        Modal.error({
          title: "您没有权限操作",
          okText: "确定",
          centered: true,
          onOk() {},
        });
      }
    }
  } else {
    if (allowList.includes(_path)) {
      router.replace({ path: _path, query: _query });
    } else {
      Modal.error({
        title: "您没有权限操作",
        okText: "确定",
        centered: true,
        onOk() {},
      });
    }
  }
};
const errorTips = (message) => {
  Modal.error({
    title: message,
    okText: "确定",
    onOk() {},
  });
};

const url = {
  navigateTo: navigateTo,
  replaceTo: replaceTo,
  errorTips: errorTips,
};

export default url;
