import router from "@/router";
// import store from "@/store";
import ls from "@/utils/local-storage";
import store from "@/store";
// import { GENERATE_ROUTES,  } from "@/store";

const allowList = [
  "Home",
  "Login",
  "Register",
  "Privacy",
  // "Notice",
  // "NoticeDetail",
  // "Park",
  // "ParkSite",
  // "Activity",
  // "ActivityDetail",
  // "Provider",
  // "ProviderDetail",
  // "Post",
  // "PostDetail",
  // "Declaration",
  // "DeclarationDetail",
  // "Job",
  // "JobDetail",
  // "Meeting",
  // "MeetingDetail",
];
const homePath = "/login";

router.beforeEach(async to => {
  if (window.screen.availWidth < 900) {
    console.log("to.fullPath", to.fullPath);
    if (to.fullPath == "/intercept") {
      return true;
    }
    if (to.fullPath != "/intercept") {
      return {
        path: "/intercept",
        replace: true
      };
    }
    return to;
  }

  const userToken = ls.get("rzUserId"); // token check
  if (!userToken) {
    // 白名单路由列表检查
    if (allowList.includes(to.name)) {
      return true;
    }
    if (to.fullPath !== homePath) {
      return {
        path: homePath,
        replace: true
      };
    }

    return to;
  }

  if (!store.getters["allowRouters"]) {
    const info = await store.dispatch("GET_INFO");
    if (info) {
      const allowRouters = await store.dispatch(`GET_ROUTERS`, info);
      if (allowRouters) {
        return { ...to, replace: true };
      }
    } else {
      return {
        path: homePath,
        replace: true
      };
    }

    return false;
  }
});
