<template>
  <a-layout>
    <a-layout-header
      :style="{
        position: 'fixed',
        zIndex: 10,
        width: '100%',
        height: '130px',
        background: '#fff',
        padding: '0',
        boxShadow: '0px 0px 29px 0px rgba(0, 0, 0, 0.08)',
      }"
    >
      <div class="header-inner">
        <div id="pageMain" class="header-left">
          <div class="logo" @click="goToPage('/')">
            <img src="~@/assets/logo.png" alt="" />
          </div>
          <!-- 首页切换园区 -->
          <div
            v-if="routerTitle == 'index'"
            :class="{
              'home-title': true,
              'home-title-disable': userData?.user_enterprise_id,
            }"
          >
            <div class="home-title-left">
              <img src="~@/assets/address.png" alt="" />
              <div class="text-ellipsis">
                {{ selectedPark.park_name || "未选择" }}
              </div>
            </div>
            <div
              v-if="!userData?.user_enterprise_id"
              class="home-title-right"
              @click="
                () => {
                  visible = true;
                }
              "
            >
              切换园区<img src="~@/assets/down.png" alt="" />
            </div>
          </div>
          <div
            v-if="
              routerTitle != 'index' &&
              routerTitle != 'login' &&
              routerTitle != 'register'
            "
            class="router-text"
          >
            <span class="v-line"></span>
            <span class="router-title">{{ routerTitle }}</span>
            <!-- 互动切换位置 -->

            <template v-if="userData.user_ismanager && routerTitle == '互动'">
              <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link" @click.prevent>
                  切换到{{
                    post_location.post_location_id == 1
                      ? "平台互动"
                      : "管理互动"
                  }}
                  <DownOutlined />
                </a>
                <template #overlay>
                  <a-menu @click="onClick">
                    <a-menu-item key="1"> 管理互动 </a-menu-item>
                    <a-menu-item key="2"> 平台互动 </a-menu-item>
                    <a-menu-divider />
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </div>
        </div>

        <div
          v-if="!userData.user_id && routerTitle != 'login'"
          class="login-text"
          @click="goToPage('/login')"
        >
          个人中心
        </div>
        <div
          v-if="userData.user_id && routerTitle != 'login'"
          class="login-text"
          @click="goToPage('/ucenter')"
        >
          个人中心
        </div>
      </div>
    </a-layout-header>
    <a-layout-content
      :style="{
        overflowY: 'auto',
        flex: '1 0 auto',
      }"
    >
      <div style="width: 100%">
        <div style="margin-top: 130px"></div>
        <router-view />
      </div>
    </a-layout-content>
    <a-layout-footer>
      <div v-if="false">
        <!-- <div class="page-main flex-between">
          <div class="footer-left">
            <div class="mb40">
              <span class="font-30 font-bold">联系我们</span>
              <span class="font-eng">CONTACT US</span>
            </div>
            <div>联系电话：0315-2806373</div>
            <div>
              地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：唐山市路南区丹凤路12号市民服务中心B区
            </div>
          </div>
          <div>
            <img src="~@/assets/qr.jpg" alt="" class="wechat" />
            <div class="wechat-desc">扫一扫联系我们</div>
          </div>
        </div> -->
        <a-space size="middle" class="copyright"
          >Copyright © 中国唐山人力资源服务产业园
          <!-- <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="color: #fff"
          >
            <i class="beian"></i>
          </a> -->
          <span>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #fff">冀ICP备11006386号-1</a>
          </span>
        </a-space>
      </div>
    </a-layout-footer>
    <a-modal
      v-model:visible="visible"
      :closable="false"
      width="1060px"
      :footer="null"
      :maskClosable="false"
      wrapClassName="park-box"
    >
      <div class="park-modal-title">
        <div class="title-cn">选择园区</div>
        <div class="title-en">CHOOSE A PARK</div>
      </div>
      <div class="park-wrap flex">
        <template v-for="(item, index) in park" :key="index">
          <div
            :class="{
              'park-item': true,
              'park-active': item.park_id == selectedPark.park_id,
            }"
            @click="
              () => {
                selectedPark.park_id = item.park_id;
                selectedPark.park_name = item.park_name;
                visible = false;
              }
            "
          >
            {{ item.park_name }}
          </div>
        </template>
      </div>
    </a-modal>
  </a-layout>
</template>
<script>
import { computed, defineComponent, ref, watch, reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import url from "../../utils/url.js";
import { useStore } from "vuex";
import { getParkList, updateUserParkIdData } from "@/api/main.js";
import { isEqual } from "lodash-es";
import { DownOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "Layout",
  components: {
    DownOutlined,
  },
  setup() {
    const store = useStore();
    const routerTitle = ref();
    const router = useRouter();
    const route = useRoute();
    const pageData = reactive({
      park: [],
      visible: false,
      selectedPark: {},
      post_location: {
        post_location_name: "平台互动",
        post_location_id: 2,
      },
    });
    const goToPage = (_page) => {
      url.navigateTo(_page);
    };

    const userData = computed(() => {
      return store.getters["userData"];
    });
    if (!userData?.value?.user_park_id) {
      //弹出选园区
      pageData.visible = true;
    } else {
      pageData.selectedPark.park_id = userData.value.user_park_id;
      pageData.selectedPark.park_name = userData.value.user_park_name;
    }
    watch(
      () => route,
      (val) => {
        routerTitle.value = val.meta.title;
        if (!userData?.value?.user_park_id) {
          //弹出选园区
          pageData.visible = true;
        } else {
          pageData.selectedPark.park_id = userData.value.user_park_id;
          pageData.selectedPark.park_name = userData.value.user_park_name;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    getParkList()
      .then((res) => {
        if (res.code == 200) {
          pageData.park = res.data.park;
        } else {
          message.error(res.msg);
        }
      })
      .catch((res) => {
        console.log(res);
      });

    const updateUserParkIdDataFun = (user_park_id) => {
      updateUserParkIdData({
        user_id: userData?.value?.user_id,
        user_park_id: user_park_id,
      })
        .then((res) => {
          if (res.code == 200) {
            store.dispatch("GET_INFO");
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    };
    const onClick = (key) => {
      // console.log("key", key);
      pageData.post_location.post_location_id = key.key;
      pageData.post_location.post_location_name =
        key.key == 1 ? "管理互动" : "平台互动";
      store.commit("SET_USERPOSTLOCATIONID", key.key);
    };
    watch(
      [() => pageData.selectedPark, () => pageData.visible],
      (newValue, oldValue) => {
        if (!isEqual(newValue, oldValue)) {
          if (userData?.value?.user_id) {
            updateUserParkIdDataFun(pageData.selectedPark?.park_id);
          } else {
            store.commit("SET_USERINFO", {
              user_park_id: pageData.selectedPark?.park_id,
              user_park_name: pageData.selectedPark?.park_name,
            });
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      userData,
      routerTitle,
      goToPage,
      ...toRefs(pageData),
      onClick,
    };
  },
});
</script>
<style lang="less">
.ant-layout {
  height: 100%;
  background: transparent;
}
.header-inner {
  width: 1200px;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.header-left {
  display: flex;
  align-items: center;
}
.logo {
  width: 264px;
  height: 68px;
  cursor: pointer;
  img {
    display: block;
    width: 239px;
    height: 58px;
  }
}
.login-text {
  cursor: pointer;
  width: 120px;
  color: #ffffff;
  height: 40px;
  background-color: #1276cb;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
}
.home-title {
  display: flex;
  background-color: #1276cb;
  align-items: center;
  width: 270px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  .home-title-left {
    width: 140px;
    height: 40px;
    background-color: #f1f1f1;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #212531;
    font-size: 18px;
    margin-left: -1px;
    img {
      margin-right: 10px;
    }
    .text-ellipsis {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .home-title-right {
    width: 130px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    img {
      margin-left: 10px;
    }
  }
}
.home-title-disable {
  width: 140px;
  background-color: #f1f1f1;
}
.router-text {
  display: flex;
  align-items: center;
  .v-line {
    width: 2px;
    height: 16px;
    background-color: #1276cb;
    border-radius: 1px;
    display: block;
  }
  .router-title {
    color: #212531;
    font-size: 24px;
    margin-left: 20px;
  }
  .ant-dropdown-link {
    margin-left: 20px;
    font-size: 18px;
  }
}
.ant-layout-content {
  min-width: 1200px;
}
.ant-layout-footer {
  background-color: #2d3e50;
  padding: 0;
  .page-main {
    min-width: 1200px;
    padding: 50px 0;
    color: #fff;
    align-items: center;
  }
  .footer-left {
    font-size: 18px;
    line-height: 40px;
    .mb40 {
      margin-bottom: 40px;
    }
    .font-30 {
      font-size: 30px;
    }
    .font-bold {
      font-weight: bold;
    }
    .font-eng {
      color: #ffffff;
      opacity: 0.6;
      font-size: 16px;
      margin-left: 20px;
    }
  }
}
.copyright {
  width: 100%;
  height: 50px;
  background-color: #1276cb;
  color: #ffffff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  .beian {
    background: url(//www.imooc.com/static/img/beianicon.png) no-repeat 0 0;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background-size: cover;
    display: inline-block;
    // margin-right: 4px;

    margin-top: -4px;
  }
}
.wechat {
  display: block;
  width: 138px;
  height: 138px;
}
.wechat-desc {
  color: #ffffff;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
}
.park-box {
  .ant-modal-content {
    width: 1060px;
    height: 400px;
    background-image: linear-gradient(
        0deg,
        #ffffff 0%,
        rgba(18, 118, 203, 0.3) 100%
      ),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    border-radius: 20px;
    .park-modal-title {
      text-align: center;
      margin-top: 26px;
      .title-cn {
        color: #212531;
        font-size: 30px;
        font-weight: bold;
      }
      .title-en {
        color: #212531;
        opacity: 0.6;
        font-size: 14px;
      }
    }
    .park-wrap {
      flex-wrap: wrap;
      padding: 0 30px;
      .park-item {
        padding: 0 39px;
        height: 50px;
        box-sizing: border-box;
        background-color: #ffffff;
        box-shadow: 0px 0px 27px 0px #f2f6f9;
        border-radius: 25px;
        color: #212531;
        font-size: 20px;
        text-align: center;
        line-height: 50px;
        margin: 30px 20px 0 20px;
        cursor: pointer;
      }
      .park-active {
        background-color: #1276cb;
        box-shadow: 0px 0px 27px 0px #f2f6f9;
        color: #fff;
      }
    }
  }
}
</style>
