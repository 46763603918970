import { createStore } from "vuex";
import ls from "@/utils/local-storage";
import { default as router, staticRoutes, routes } from "@/router";
import {
  onLogin,
  getUserData,
  onLoginByPassword,
  updateUserPasswordData
} from "@/api/main.js";
import { message } from "ant-design-vue";
import url from '../utils/url'
export default createStore({
  state: {
    user: {
      access_token: 11,
      user_avatar: null,
      user_id: null,
      user_nickname: null,
      user_openid: null,
      user_phone: null,
      user_status: null,
      user_time: null,
      user_park_id: null,
      user_park_name: null
    },
    access_token: null,
    allowRouters: null,
    user_post_location_id: 2
  },
  getters: {
    userId: state => state.user.user_id,
    userData: state => state.user,
    allowRouters: state => state.allowRouters,
    userPostLocationId: state => state.user_post_location_id
  },
  mutations: {
    ["SET_USERINFO"]: (state, info) => {
      // console.log('state',state);
      state.user = info;
      // state.access_token = info.access_token;
      ls.set("rzUserId", info.user_id);
    },
    ["SET_USERPOSTLOCATIONID"]: (state, info) => {
      // console.log("state", info);
      state.user_post_location_id = info;
    },
    ["SET_ROUTERS"]: (state, info) => {
      state.allowRouters = info;
    },
    ["SET_TOKEN"]: (state, info) => {
      state.access_token = info;
    },
    ["RESET_USERINFO"]: state => {
      let _userData = {
        access_token: null,
        user_avatar: null,
        user_id: null,
        user_nickname: null,
        user_openid: null,
        user_phone: null,
        user_status: null,
        user_time: null,
        user_park_id: null,
        user_park_name: null
      };
      state.user = _userData;
      state.access_token = null;
      ls.set("rzUserId", null);
      ls.set("rzAccessToken", null);
    }
  },
  actions: {
    // 存入登录localStorage 和 userinfo
    ["LOGIN"]({ commit }, info) {
      return new Promise((resolve, reject) => {
        // onLoginByPassword
        if (info.loginMehod == "tab1") {
          onLogin(info)
            .then(res => {
              if (res.code == 200) {
                ls.set("rzAccessToken", res.data.access_token);
                commit("SET_USERINFO", res.data);
                if (info.isRegister) {
                  updateUserPasswordData({
                    user_id: res.data.user_id,
                    user_password: info.user_password
                  }).then(result => {
                    if (result.code == 200) {
                      resolve(res);
                    } else {
                      message.error(res.msg);
                      reject(res);
                    }
                  });
                } else {
                  resolve(res);
                }
              } else {
                message.error(res.msg);
                reject(res);
              }
            })
            .catch(error => {
              reject(error);
            });
        } else {
          onLoginByPassword(info)
            .then(res => {
              if (res.code == 200) {
                resolve(res);
                ls.set("rzAccessToken", res.data.access_token);
                commit("SET_USERINFO", res.data);
              } else {
                message.error(res.msg);
                reject(res);
              }
            })
            .catch(error => {
              reject(error);
            });
        }
      });
    },
    ["GET_ROUTERS"]({ commit }, info) {
      return new Promise(resolve => {
        // console.log("GET_ROUTERS", info);
        const { children: _, ...mainRoutes } = staticRoutes[0];
        const route = { ...mainRoutes, children: routes };
        router.addRoute(route);
        commit("SET_ROUTERS", routes);
        resolve(routes);
      });
    },
    ["LOGOUT"]({ commit }) {
      return new Promise(resolve => {
        ls.set("rzUserId", null);
        commit("RESET_USERINFO");
        resolve(true);
      });
    },
    ["GET_INFO"]({ state, dispatch, commit }) {
      return new Promise((resolve, reject) => {
        let token = ls.get("rzAccessToken");
        let userId = ls.get("rzUserId");
        if (token && userId) {
          commit("SET_TOKEN", token);
          getUserData({ user_id: userId })
            .then(res => {
              if (res.code == 200 && res.data.user) {
                commit("SET_USERINFO", res.data.user);
                resolve(res.data.user);
               
              } else {
                commit("RESET_USERINFO");
                resolve(false);
              }
            })
            .catch(res => {
              console.log(res);
              reject(false);
            });
        } else {
          commit("RESET_USERINFO");
          reject(false);
        }
      });
    }
  },
  modules: {}
});
