<template>
  <a-config-provider :locale="zhCN">
    <router-view />
  </a-config-provider>
</template>
<script>
import { defineComponent } from "vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";

export default defineComponent({
  setup() {
    // 禁止右键 键盘打开浏览器控制台
    // document.oncontextmenu = new Function("return false;");
    // document.onkeydown =
    //   document.onkeyup =
    //   document.onkeypress =
    //     function (event) {
    //       var e = event || window.event || arguments.callee.caller.arguments[0];
    //       if (e && (e.keyCode == 123 || e.keyCode == 116)) {
    //         console.log("event", e.keyCode);
    //         e.returnValue = false;
    //         return false;
    //       }
    //       if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
    //         console.log("event", e);
    //         e.returnValue = false;
    //         return false;
    //       }
    //     };

    return { zhCN };
  },
});
</script>
